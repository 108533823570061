import { Box, Button, Grid, Typography } from "@mui/material"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import CustomFieldInput from "components/CustomFieldInput"
import { Formik, useFormikContext } from "formik"
import * as Yup from "yup"
import { apiEndpoints } from "generated/apiEndpoints"
import { useApi } from "auth/useApi3"
import DefaultButton from "components/Button"
import {
  StyledFontAwesomeIconEdit,
  StyledIconButtonEdit,
  StyledListItem,
  StyledSwitchDisabled
} from "shared/vehicle/CustomFields/CustomFields.styled"
import { customFieldValidation } from "./customFieldValidation"

const CustomFieldValue = ({ customField, value }) => {
  switch (customField.type) {
    case "Date":
      return (
        <Typography variant="bodyMedium" fontWeight={600}>
          {value ? moment.utc(value).local().format("YYYY-MM-DD") : "-"}
        </Typography>
      )
    case "Text":
    case "Numeric":
      return (
        <Typography variant="bodyMedium" fontWeight={600}>
          {value ? value : "-"}
        </Typography>
      )
    case "Boolean":
      return value === "True" ? (
        <StyledSwitchDisabled disabled defaultChecked />
      ) : (
        <StyledSwitchDisabled disabled />
      )
    case "Notification":
    case "RepeatedNotification":
      return (
        <Box display="flex" flexDirection="column">
          {customField?.dateInfo?.date && (
            <Grid container justifyContent="flex-start">
              <Typography variant="bodyMedium" fontWeight={600}>
                {moment(customField.dateInfo.date).utc().format("YYYY-MM-DD")}
              </Typography>
              <Typography>&nbsp;{`(${customField.dateInfo.name})`}</Typography>
            </Grid>
          )}
          {customField?.numberInfo?.number && (
            <Grid container justifyContent="flex-start">
              <Typography variant="bodyMedium" fontWeight={600}>
                {customField.numberInfo.number}
              </Typography>
              <Typography>&nbsp;{`(${customField.numberInfo.name})`}</Typography>
            </Grid>
          )}
        </Box>
      )
  }
  return <Typography>{customField.value}</Typography>
}

const CustomFieldColumn = ({ vehicleData, data, editMode, setEditMode }) => {
  const [vehicleResult, vehicleApi] = useApi()
  const [formikValues, setFormikValues] = useState({})
  const [yupValidation, setYupValidation] = useState()

  useEffect(() => {
    const customFieldObject = {}
    data.forEach((customValue) => {
      customFieldObject["customField" + customValue.customFieldId] = customValue.value
    })
    setFormikValues({ ...customFieldObject, customFieldValues: data })
  }, [])

  useEffect(() => {
    const shape = {}
    data.map((customField) => {
      shape["customField" + customField.customFieldId] = customFieldValidation(customField)
    })
    setYupValidation(Yup.object().shape(shape))
  }, [data])

  useEffect(() => {
    if (vehicleResult.status !== 2) return
    setTimeout(() => {
      setEditMode(!editMode)
    }, 600)
  }, [vehicleResult])

  function handleSubmit(values, submitProps) {
    const submitData = { ...vehicleData, ...values }
    if (editMode) {
      vehicleApi.put(apiEndpoints.vehicleregistryadmin.updatevehicle, submitData)
    } else {
      vehicleApi.post(apiEndpoints.vehicleregistryadmin.addvehicle, submitData)
    }
    submitProps.resetForm({ values })
  }

  return (
    <Grid item md={12} sx={{ paddingLeft: "30px" }}>
      <Grid container direction="column">
        <Formik
          validationSchema={yupValidation}
          onSubmit={handleSubmit}
          initialValues={formikValues}
          enableReinitialize={true}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            dirty,
            initialValues,
            setFieldTouched
          }) => (
            <form id="CustomFields" onSubmit={handleSubmit} noValidate>
              {data &&
                data.map((customField, index) => {
                  return (
                    <StyledListItem>
                      <Typography
                        sx={{
                          width: "240px",
                          marginRight: "18px"
                        }}>
                        {customField.name}
                      </Typography>
                      {editMode ? (
                        <>
                          <CustomFieldInput
                            setFieldTouched={setFieldTouched}
                            values={values}
                            customField={customField}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            index={index}
                            initialValues={initialValues}
                          />
                        </>
                      ) : (
                        <CustomFieldValue customField={customField} value={customField.value} />
                      )}
                    </StyledListItem>
                  )
                })}
              <Grid container justifyContent={"flex-end"} pt={3} pb={5} spacing={5}>
                {editMode ? (
                  <Box display="flex" gap={5}>
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ width: "150px" }}
                      onClick={() => {
                        setEditMode(false)
                      }}>
                      Avbryt
                    </Button>
                    <DefaultButton
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!dirty}
                      sx={{ width: "150px" }}
                      result={vehicleResult}>
                      Spara
                    </DefaultButton>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

const CustomFields = ({ vehicleData, data }) => {
  const [editMode, setEditMode] = useState(false)

  return (
    <Box p={3}>
      <Grid container direction="row" spacing={5} sx={{ position: "relative" }}>
        <Grid md={10}>
          <CustomFieldColumn
            vehicleData={vehicleData}
            data={data}
            editMode={editMode}
            setEditMode={setEditMode}
          />
          <StyledIconButtonEdit
            top={10}
            right={-30}
            editMode={editMode}
            size="medium"
            color="primary"
            onClick={() => {
              setEditMode(!editMode)
            }}>
            <StyledFontAwesomeIconEdit editMode={editMode} icon={faPenToSquare} size="lg" />
          </StyledIconButtonEdit>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CustomFields

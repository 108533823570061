import React, { useEffect, useRef, useState } from "react"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { apiEndpoints } from "generated/apiEndpoints"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import { Box, Button, InputLabel, List, ListItem, ListItemText, Typography } from "@mui/material"
import { Formik } from "formik"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { CircleIcon } from "components/icons/CircleIcon"
import { faFlag } from "@fortawesome/pro-solid-svg-icons"
import { StyledListItemText } from "../InspectionService.styled"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { t } from "i18next"
import * as Yup from "yup"
import SubmitButton from "components/Button"

const expenseValidationSchema = Yup.object().shape({
  name: Yup.string().required("Kostnadsnamn är obligatorisk"),
  amount: Yup.number().positive("Måste vara positivt tal").required("Mätarställning är obligatoriskt"),
  date: Yup.date().typeError("Datum är obligatoriskt").required("Datum är obligatoriskt")
})

interface ExpenseSidebarProps {
  handleSubmit: () => void
  showSidebar: boolean
  setShowSidebar: (value: ((prevState: boolean) => boolean) | boolean) => void
  clickedExpense: any
  vehicleData: any
}

interface VehicleExpenseFormikValues {
  id: number
  name: string
  amount: number
  date: moment.Moment | null
  description: string
  category?: number
  vehicleId: number
}

const initialValues: VehicleExpenseFormikValues = {
  id: 0,
  name: "",
  amount: 0,
  date: null,
  description: "",
  vehicleId: 0
}

export const ExpenseSidebar = ({ vehicleData, clickedExpense, setShowSidebar, showSidebar, handleSubmit }: ExpenseSidebarProps) => {
  const [formikValues, setFormikValues] = useState(initialValues)
  const [expenseResult, expenseApi] = useApi() as [IApiResult, IApiFunc]
  const formRef = useRef() as any

  const handleFormikSubmit = (values: VehicleExpenseFormikValues) => {
    const formValues = {
      ...values,
      date: moment(values.date).add(12, "hours").utc(),
      vehicleId: vehicleData.id
    }

    if (values.id === 0) {
      const url = apiEndpoints.vehicleexpense.createvehicleexpense
      return expenseApi.post(url, formValues)
    }
    const url = apiEndpoints.vehicleexpense.updatevehicleexpense
    return expenseApi.put(url, formValues)
  }

  useEffect(() => {
    if (expenseResult.status !== 2) return
    handleSubmit()
    setShowSidebar(false)
  }, [expenseResult])

  useEffect(() => {
    if (clickedExpense === undefined) {
      setFormikValues(initialValues)
      formRef.current.resetForm()
    } else {
      setFormikValues({
        ...clickedExpense
      })
    }
  }, [clickedExpense])

  useEffect(() => {
    if (showSidebar) return
    setFormikValues(initialValues)
  }, [showSidebar])

  return (
    <Sidebar showSidebar={showSidebar}>
      <Box sx={{ height: "100%", margin: "20px" }}>
        <Formik
          innerRef={formRef}
          initialValues={formikValues}
          validationSchema={expenseValidationSchema}
          onSubmit={(values, { resetForm }) => {
            handleFormikSubmit(values)
            resetForm()
          }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isValid,
            dirty,
            setFieldTouched
          }) => (
            <form style={{ height: "100%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
              <RouterPrompt />
              <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box style={{ position: "absolute", left: "-20px" }}>
                  <CloseSidebarButton
                    toggleMenu={() => {
                      setShowSidebar(!showSidebar)
                    }}
                  />
                </Box>
                <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                  <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <CircleIcon
                        icon={faFlag}
                        color="primary"
                        size="lg"
                        sx={{ marginRight: "15px" }}
                      />
                      <Box justifyContent="center">
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="h2">
                              {clickedExpense ? "Redigera " + clickedExpense.name : "Lägg till kostnad"}
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Kostnad
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <TextField
                        placeholder="Ange kostnad"
                        autoComplete="off"
                        fullWidth
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Belopp
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <TextField
                        placeholder="Ange belopp i kr"
                        autoComplete="off"
                        fullWidth
                        type="number"
                        id="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.amount && errors.amount ? true : false}
                        helperText={touched.amount && errors.amount ? errors.amount : null}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Datum
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <DatePicker
                        value={values.date ? moment(values.date) : null}
                        format={"YYYY-MM-DD"}
                        onChange={(value) => {
                          setFieldValue(
                            "date",
                            moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null
                          )
                        }}
                        slotProps={{
                          textField:
                            {
                              variant: "outlined",
                              placeholder: "Datum vid köp",
                              helperText: touched.date && errors.date ? errors.date : null,
                              error: touched.date && errors.date ? true : false,
                              sx: { width: "100%" }
                            }
                        }}
                      />
                    }
                  />
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Beskrivning
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <TextField
                        placeholder="Beskriv vad kostnaden gäller"
                        multiline={true}
                        rows={4}
                        autoComplete="off"
                        fullWidth
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && errors.description ? true : false}
                        helperText={touched.description && errors.description ? errors.description : null}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
                <Box sx={{ marginTop: "Auto", marginBottom: "26px", width: "100%" }}>
                  <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                    <SubmitButton
                      result={expenseResult}
                      sx={{ width: "100%", margin: "0 10px" }}
                      type="button"
                      variant="contained"
                      onClick={() => {
                        if (!dirty) {
                          Object.keys(values).forEach(valueName => {
                            setFieldTouched(valueName, true)
                          })
                          return
                        }
                        if (!isValid) {
                          Object.keys(values).forEach(valueName => {
                            setFieldTouched(valueName, true)
                          })
                          return
                        }
                        handleSubmit()
                      }}
                    >
                      Spara
                    </SubmitButton>
                    <Button
                      sx={{ width: "100%", margin: "0 10px" }}
                      type="button"
                      variant={"outlined"}
                      onClick={() => {
                        setShowSidebar(false)
                      }}>
                      {t("global.buttons.cancel")}
                    </Button>
                  </ListItem>
                </Box>
              </List>
            </form>
          )}
        </Formik>
      </Box>
    </Sidebar>
  )
}
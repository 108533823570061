import React, { useContext, useEffect, useMemo } from "react"
import { AppContext } from "components/AppContextProvider"
import { Tooltip } from "@mui/material"
import { VehicleStatus } from "./Checks.interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarCirclePlus,
  faCheck,
  faPenToSquare,
  faSquareP,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons"
import moment from "moment/moment"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { StyledTypography } from "shared/vehicle/InspectionService.styled"
import ClpTable from "components/ClpTable"
import { StyledInspectionStatusIcon, StyledSpanError } from "./Checks.styled"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"
import { StyledIconButtonNoPadding } from "./InspectionsHistoryTable.styled"


export const ChecksTable = ({ checksResult, openInspectionSidebar, openServiceSidebar }: any) => {
  const appContext = useContext(AppContext)
  const history = useHistory()

  const columns: any[] = useMemo(
    () => [
      {
        accessor: "status",
        Cell: ({ cell }: any) => {
          return (
            <StyledIconButtonNoPadding color="primary" size="large">
              {cell.row.original.status === VehicleStatus.DrivingBan ? (
                <Tooltip title={VehicleStatus.DrivingBan}>
                  <StyledInspectionStatusIcon
                    status={VehicleStatus.DrivingBan}
                    icon={faTriangleExclamation}
                    size="2xs"
                  />
                </Tooltip>
              ) : cell.row.original.status === VehicleStatus.Deregistered ? (
                <Tooltip title={VehicleStatus.Deregistered}>
                  <StyledInspectionStatusIcon
                    status={VehicleStatus.Deregistered}
                    icon={faSquareP}
                    size="2xs"
                  />
                </Tooltip>
              ) : cell.row.original.status === VehicleStatus.Delayed ? (
                <></>
              ) : (
                <></>
              )}
            </StyledIconButtonNoPadding>
          )
        },
        Header: "Status"
      },
      {
        accessor: "regNumber",
        Header: "Regnr"
      },
      {
        accessor: "internNumber",
        Header: "Internnr"
      },
      {
        accessor: "customFieldName",
        Header: "Kontroll"
      },
      {
        accessor: (row: any) => row?.attendanceDate ? moment(row?.attendanceDate).local().format("YYYY-MM-DD") : "",
        Cell: ({ cell }: any) => {
          const original = cell.row.original
          const printDate = original.attendanceDate ? moment(original.attendanceDate).local().format("YYYY-MM-DD") : ""
          const isDatePassed = moment().isSameOrAfter(original.attendanceDate)
          if (
            original.status === VehicleStatus.DrivingBan ||
            original.status === VehicleStatus.Delayed ||
            original.status === VehicleStatus.Deregistered ||
            isDatePassed
          ) {
            return <StyledSpanError>{printDate}</StyledSpanError>
          }
          return <span>{printDate}</span>
        },
        Header: "Inställelse"
      },
      {
        accessor: "attendanceKm",
        Header: "Inst. km"
      },
      {
        accessor: (row: any) => (row.inspectionBooking ? row.inspectionBooking.carWorkshop.name : ""),
        Header: "Plats"
      },
      {
        accessor: (row: any) => (row.inspectionBooking ? moment(row.inspectionBooking.dateTime).local().format("YYYY-MM-DD HH:mm") : ""),
        Header: "Datum/tid"
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        Cell: ({ cell }: any) => {
          const controlType = cell.row.original.controlType
          if (controlType === "Besiktning") {
            return (
              <StyledIconButtonNoPadding
                color="primary"
                size="large"
                onClick={() => {
                  return openInspectionSidebar(cell.row.original)
                }}>
                {cell.row.original.inspectionBooking ? (
                  <>
                    <FontAwesomeIcon icon={faPenToSquare} size="2xs" />
                    <StyledTypography>Ändra</StyledTypography>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCalendarCirclePlus} size="2xs" />
                    <StyledTypography>Boka</StyledTypography>
                  </>
                )}
              </StyledIconButtonNoPadding>
            )
          } else if (controlType === "Service") {
            return <StyledIconButtonNoPadding
                color="primary"
                size="large"
                onClick={() => {
                  openServiceSidebar(cell.row.original)
                }}>
              {cell.row.original.status === null ? (
                <>
                  <FontAwesomeIcon icon={faCheck} size="2xs" />
                  <StyledTypography>Godkänn</StyledTypography>
                </>
              ) : (
                <></>
              )}
            </StyledIconButtonNoPadding>
          }
        },
        accessor: "action",
        Header: "Hantera",
        hiddenFilter: true
      })
    }
  }, [])

  return (
    <ClpTable
      columns={columns}
      cellProps={(row: any) => ({
        onClick: () => history.push(allRoutes.VehicleregistryVehiclesDetails(row.original.vehicleId))
      })}
      rows={checksResult.status === 2 && checksResult.data}
      status={checksResult.status}
    />
  )
}